import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [2,3];

export const dictionary = {
		"/": [4],
		"/golfers": [~5],
		"/golfers/[slug]": [~6],
		"/login": [7],
		"/sentry-example": [8],
		"/tpc2023": [~9,[2]],
		"/tpc2023/rules": [10,[2]],
		"/vnc2023": [~11,[3]],
		"/vnc2023/rules": [12,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';